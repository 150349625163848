import React from "react";
import { Fragment } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";

function Cleaning() {

    return(

    
    <Fragment>

<div class="body_wrap">


<div class="progress-wrap">
			<svg class="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
			<path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"/>
			</svg>
</div>

		
        <Header />
	

		 <aside class="slide-bar">
            <div class="close-mobile-menu">
			<Link to={""}><i className="fal fa-times"></i></Link>
            </div>
</aside>
        <div class="body-overlay"></div>

<section id="clinox-breadcrumb" class="clinox-breadcrumb-section position-relative top-position" data-background="assets/img/maintenance-head.jpg">
		<div class="banner-shape position-absolute"><img src="assets/img/banner/bread-bg.png" alt="" /></div>
		<div class="container">
			<div class="breadcrumb-content headline ul-li position-relative">
				<h2>Cleaning</h2>
				<ul>
					<li><Link to="/">Home</Link></li>
					<li>Maintenance</li>
				</ul>
			</div>
		</div>
	</section>	



	<section id="clinox-about-service" class="clinox-about-service-section page-section-padding">
		<div class="container">
			<div class="clinox-about-service-content">
				<div class="row">
					<div class="col-lg-6">
						<div class="clinox-about-service-img-wrap position-relative">
							<span class="img-shape position-absolute"></span>
							<div class="clinox-about-service-img">
								<img src="assets/img/cleaning.jpg" />
							</div>
						</div>
					</div>
					<div class="col-lg-6">
						<div class="clinox-about-service-text-wrap">
							<div class="clinox-section-title  headline pera-content">
								<span class="sub-title">About Services</span>
								<h2>Cleaning</h2>
							</div>
							<div class="clinox-about-service-text pera-content">
								<p>"Keep your home or office spotless with Blue Genie Cleaning Services. Our team of experienced cleaners use the latest techniques and equipment to provide you with a deep and thorough clean. Whether it's a one-time cleaning or a regular service, our goal is to exceed your expectations and leave you with a sparkling space. Book now and see the difference a professional cleaning can make. Trust Blue Genie Cleaning Services to make your space shine!"</p>
								<p>
									
								</p>
								<div class="clinox-btn">
									<Link class="d-flex align-items-center justify-content-center"
									to={"clinox-contact"} ><span>Let's Talk</span></Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>	





	<section id="clinox-service-offer" class="clinox-service-offer-section">
		<div class="container">
			<div class="clinox-section-title text-center headline pera-content">
				<span class="sub-title">Services Offer</span>
				<h2>Specific Offers of The Service</h2>
			</div>
			<div class="clinox-service-offer-content">
				<div class="row">
					<div class="col-lg-4">
						<div class="clinox-service-offer-item d-flex">
							<div class="inner-serial d-flex align-items-center justify-content-center">
								01
							</div>
							<div class="inner-text headline pera-content">
								<h3>Polish Floors</h3>
								<p>Polishing floors restores their shine, enhances durability, and protects against scratches and stains. Regular polishing extends the life of floors.</p>
							</div>
						</div>
					</div>
					<div class="col-lg-4">
						<div class="clinox-service-offer-item d-flex">
							<div class="inner-serial d-flex align-items-center justify-content-center">
								02
							</div>
							<div class="inner-text headline pera-content">
								<h3>Clean Appliances</h3>
								<p>Blue Genie uses environmentally friendly cleaning appliances in its services to provide efficient and eco-friendly cleaning solutions to clients..</p>
							</div>
						</div>
					</div>
					<div class="col-lg-4">
						<div class="clinox-service-offer-item d-flex">
							<div class="inner-serial d-flex align-items-center justify-content-center">
								03
							</div>
							<div class="inner-text headline pera-content">
								<h3>Emptying Trash</h3>
								<p>Blue genie are the best cleaners in town. Call us and our friendly blue genie cleaners will pick up your trash and take away your trash, leaving you with a clean.</p>
							</div>
						</div>
					</div>
					<div class="col-lg-4">
						<div class="clinox-service-offer-item d-flex">
							<div class="inner-serial d-flex align-items-center justify-content-center">
								04
							</div>
							<div class="inner-text headline pera-content">
								<h3>Disinfect Trash</h3>
								<p>We offer a quick and reliable cleaning service in Dubai. Blue genie use Disinfect Trash to clean your home.</p>
							</div>
						</div>
					</div>
					<div class="col-lg-4">
						<div class="clinox-service-offer-item d-flex">
							<div class="inner-serial d-flex align-items-center justify-content-center">
								05
							</div>
							<div class="inner-text headline pera-content">
								<h3>Replace Liner</h3>
								<p>Blue Genie Replace Liner services offers, reliable liner replacement for pools and spas. Trust for a seamless liner change.</p>
							</div>
						</div>
					</div>
					<div class="col-lg-4">
						<div class="clinox-service-offer-item d-flex">
							<div class="inner-serial d-flex align-items-center justify-content-center">
								06
							</div>
							<div class="inner-text headline pera-content">
								<h3>Refurbishments</h3>
								<p>Blue Genie offers comprehensive refurbishment services to revitalize your space with expert craftsmanship and attention to detail.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>		


	<section id="clinox-service-benifit" class="clinox-service-benifit-section">
		<div class="container">
			<div class="clinox-service-benifit-content">
				<div class="row">
					<div class="col-lg-6">
						<div class="clinox-service-benifit-text-wrap">
							<div class="clinox-section-title  headline pera-content">
								<span class="sub-title">Key Benifit</span>
								<h2>Benefits of the Service
								</h2>
								<p>Blue Genie's home cleaning service provides a variety of benefits including improved air quality, reduced allergens, increased comfort, and a clean, organized living space. It saves time and effort, and provides peace of mind knowing your home is professionally cleaned. Plus, it helps extend the life of your household items and maintains the value of your home.</p>
							</div>
							<div class="service-benifit-feature ul-li">
								<ul>
									<li>Trained professionals</li>
									<li>High-quality cleaning products</li>
									<li>Customizable cleaning plans</li>
									<li>Time-saving</li>
									<li>Improved health</li>
									<li>Stress reduction</li>
									<li>Enhanced comfort</li>
									<li>Improved air quality</li>
									<li>Peace of mind</li>
									<li>Increased property value</li>

								</ul>
							</div>
						</div>
					</div>
					<div class="col-lg-6">
						<div class="clinox-about-service-img-wrap position-relative">
							<span class="img-shape position-absolute"></span>
							<div class="clinox-about-service-img">
								<img src="assets/img/about/as2.jpg" />
							</div>
							
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>		

	
	
	<section id="clinox-contact" class="clinox-contact-section">
		<div class="container">
			<div class="clinox-contact-content">
				<div class="row">
				<div class="col-lg-6">
								<div class="row">
									<div class="col-md-12">
										<img src="assets/img/lets-talk.jpg" alt="" />
									</div>
								</div>

					</div>
					<div class="col-lg-6">
						<div class="clinox-contact-form-wrap">
							<div class="clinox-section-title headline pera-content pr-text-in">
								<h2>
									<span class="pr-text-in_item1">
										<span class="pr-text-in_item2">
											<span class="pr-text-in_item3">
												Let's <b>Talk</b>
											</span>
										</span>
									</span>
								</h2>
							</div>
							<div class="clinox-contact-form">
								<form action="#" method="get">
									<div class="row">
										<div class="col-md-6">
											<input type="text" name="name" placeholder="Name" />
										</div>
										<div class="col-md-6">
											<input type="email" name="email" placeholder="Email" />
										</div>
										<div class="col-md-6">
											<input type="text" name="phone" placeholder="Phone" />
										</div>
										<div class="col-md-6">
											<div class="service-select position-relative">
												<select name="#">
													<option value="#">Service</option>
													<option value="#">Cleaning Services</option>
													<option value="#">Maintenance Services</option>
												</select>
											</div>
										</div>
										
									</div>
									<button type="submit">Submit Now</button>
								</form>
							</div>
						</div>
					</div>
					
				</div>
			</div>
		</div>
	</section>		


	<Footer/>
	</div>




        </Fragment>
    )
}

export default Cleaning;