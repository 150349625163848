import React, { Fragment } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";

function AirConditioning(){

    return(
        
        <Fragment>
<div className="body_wrap">



<div className="progress-wrap">
    <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
    <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"/>
    </svg>
</div>

<Header />


 <aside className="slide-bar">
    <div className="close-mobile-menu">
        <a href="javascript:void(0);"><i className="fal fa-times"></i></a>
    </div>

     <nav className="side-mobile-menu">
        <ul id="mobile-menu-active">
           <li><a href="index.html">Home</a></li>
            <li><a href="about-us.html">About us</a></li>
            <li className="dropdown">
                <a href="#">Cleaning</a>
                <ul className="submenu clearfix">
                    <li><a href="home-cleaning-services.html">Home Cleaning Services</a></li>
                    <li><a href="home-disinfection-service.html">Home Disinfection Service</a></li>
                    <li><a href="pest-control.html">Pest Control</a></li>
                    <li><a href="deep-cleaning-services.html">Deep Cleaning Services</a></li>
                    <li><a href="home-sanitization-service.html">Home Sanitization Service</a></li>
                    <li><a href="move-in-cleaning.html">Move In Cleaning</a></li>
                    <li><a href="sofa-cleaning.html">Sofa Cleaning</a></li>
                    <li><a href="handyman-services.html">Handyman Services</a></li>
                    <li><a href="housekeeping-services.html">Housekeeping Services</a></li>
                    <li><a href="babysitting-service.html">Babysitting Service</a></li>
                </ul>
            </li>
            <li className="dropdown">
                <a href="#">Maintenance</a>
                <ul className="submenu clearfix">
                    <li><a href="air-conditioning.html">Air Conditioning</a></li>
                    <li><a href="cleaning.html">Cleaning</a></li>
                    <li><a href="electrical.html">Electrical</a></li>
                    <li><a href="plumbing.html">Plumbing</a></li>
                    <li><a href="disinfection.html">Disinfection</a></li>
                    <li><a href="tiling-and-carepentry.html">Tiling and Carepentry</a></li>
                </ul>
            </li>
            <li><a href="annual-contract.html">Annual Contract</a></li>
           <li><a href="#">Communities</a></li>
            <li><a href="contact.html">Contact us</a></li>
        </ul>
    </nav>

</aside>
<div className="body-overlay"></div>




<section id="clinox-breadcrumb" className="clinox-breadcrumb-section position-relative top-position" data-background="assets/img/maintenance-head.jpg">
<div className="banner-shape position-absolute"><img src="assets/img/banner/bread-bg.png" alt="" /></div>
<div className="container">
    <div className="breadcrumb-content headline ul-li position-relative">
        <h2>Air Conditioning</h2>
        <ul>
            <li><Link to="/">Home</Link></li>
            <li>Maintenance</li>
        </ul>
    </div>
</div>
</section>	


<section id="clinox-about-service" className="clinox-about-service-section page-section-padding">
<div className="container">
    <div className="clinox-about-service-content">
        <div className="row">
            <div className="col-lg-6">
                <div className="clinox-about-service-img-wrap position-relative">
                    <span className="img-shape position-absolute"></span>
                    <div className="clinox-about-service-img">
                        <img src="assets/img/airconditioning.jpg" />
                    </div>
                </div>
            </div>
            <div className="col-lg-6">
                <div className="clinox-about-service-text-wrap">
                    <div className="clinox-section-title  headline pera-content">
                        <span className="sub-title">About Services</span>
                        <h2>Air Conditioning
                        </h2>
                    </div>
                    <div className="clinox-about-service-text pera-content">
                        <p>Stay cool and comfortable all year round with Blue Genie's expert air conditioning services. With over 20 years of experience, we offer top-quality repair, maintenance and installation services to ensure your AC unit is functioning at its best.</p>
                        <p>
                            Our team of certified technicians are equipped to handle all types of AC systems and will diagnose and fix any issue in a timely and efficient manner. Trust Blue Genie for reliable and affordable air conditioning services. Contact us today to schedule an appointment!
                        </p>
                        <div className="clinox-btn">
                            <a className="d-flex align-items-center justify-content-center" href="#clinox-contact"><span>Let's Talk</span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</section>	




<section id="clinox-service-offer" className="clinox-service-offer-section">
<div className="container">
    <div className="clinox-section-title text-center headline pera-content">
        <span className="sub-title">Services Offer</span>
        <h2>Specific Offers of The Service</h2>
    </div>
    <div className="clinox-service-offer-content">
        <div className="row">
            <div className="col-lg-4">
                <div className="clinox-service-offer-item d-flex">
                    <div className="inner-serial d-flex align-items-center justify-content-center">
                        01
                    </div>
                    <div className="inner-text headline pera-content">
                        <h3>Polish Floors</h3>
                        <p>Polishing floors restores their shine, enhances durability, and protects against scratches and stains. Regular polishing extends the life of floors.</p>
                    </div>
                </div>
            </div>
            <div className="col-lg-4">
                <div className="clinox-service-offer-item d-flex">
                    <div className="inner-serial d-flex align-items-center justify-content-center">
                        02
                    </div>
                    <div className="inner-text headline pera-content">
                        <h3>Clean Appliances</h3>
                        <p>Blue Genie uses environmentally friendly cleaning appliances in its services to provide efficient and eco-friendly cleaning solutions to clients..</p>
                    </div>
                </div>
            </div>
            <div className="col-lg-4">
                <div className="clinox-service-offer-item d-flex">
                    <div className="inner-serial d-flex align-items-center justify-content-center">
                        03
                    </div>
                    <div className="inner-text headline pera-content">
                        <h3>Emptying Trash</h3>
                        <p>Blue genie are the best cleaners in town. Call us and our friendly blue genie cleaners will pick up your trash and take away your trash, leaving you with a clean.</p>
                    </div>
                </div>
            </div>
            <div className="col-lg-4">
                <div className="clinox-service-offer-item d-flex">
                    <div className="inner-serial d-flex align-items-center justify-content-center">
                        04
                    </div>
                    <div className="inner-text headline pera-content">
                        <h3>Disinfect Trash</h3>
                        <p>We offer a quick and reliable cleaning service in Dubai. Blue genie use Disinfect Trash to clean your home.</p>
                    </div>
                </div>
            </div>
            <div className="col-lg-4">
                <div className="clinox-service-offer-item d-flex">
                    <div className="inner-serial d-flex align-items-center justify-content-center">
                        05
                    </div>
                    <div className="inner-text headline pera-content">
                        <h3>Replace Liner</h3>
                        <p>Blue Genie Replace Liner services offers, reliable liner replacement for pools and spas. Trust for a seamless liner change.</p>
                    </div>
                </div>
            </div>
            <div className="col-lg-4">
                <div className="clinox-service-offer-item d-flex">
                    <div className="inner-serial d-flex align-items-center justify-content-center">
                        06
                    </div>
                    <div className="inner-text headline pera-content">
                        <h3>Refurbishments</h3>
                        <p>Blue Genie offers comprehensive refurbishment services to revitalize your space with expert craftsmanship and attention to detail.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</section>		

<section id="clinox-service-benifit" className="clinox-service-benifit-section">
<div className="container">
    <div className="clinox-service-benifit-content">
        <div className="row">
            <div className="col-lg-6">
                <div className="clinox-service-benifit-text-wrap">
                    <div className="clinox-section-title  headline pera-content">
                        <span className="sub-title">Key Benifit</span>
                        <h2>Benefits of the Service
                        </h2>
                        <p>Blue Genie's home cleaning service provides a variety of benefits including improved air quality, reduced allergens, increased comfort, and a clean, organized living space. It saves time and effort, and provides peace of mind knowing your home is professionally cleaned. Plus, it helps extend the life of your household items and maintains the value of your home.</p>
                    </div>
                    <div className="service-benifit-feature ul-li">
                        <ul>
                            <li>Trained professionals</li>
                            <li>High-quality cleaning products</li>
                            <li>Customizable cleaning plans</li>
                            <li>Time-saving</li>
                            <li>Improved health</li>
                            <li>Stress reduction</li>
                            <li>Enhanced comfort</li>
                            <li>Improved air quality</li>
                            <li>Peace of mind</li>
                            <li>Increased property value</li>

                        </ul>
                    </div>
                </div>
            </div>
            <div className="col-lg-6">
                <div className="clinox-about-service-img-wrap position-relative">
                    <span className="img-shape position-absolute"></span>
                    <div className="clinox-about-service-img">
                        <img src="assets/img/about/as2.jpg" />
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</div>
</section>		



<section id="clinox-contact" className="clinox-contact-section">
<div className="container">
    <div className="clinox-contact-content">
        <div className="row">
        <div className="col-lg-6">
                        <div className="row">
                            <div className="col-md-12">
                                <img src="assets/img/lets-talk.jpg" alt="" />
                            </div>
                        </div>

            </div>
            <div className="col-lg-6">
                <div className="clinox-contact-form-wrap">
                    <div className="clinox-section-title headline pera-content pr-text-in">
                        <h2>
                            <span className="pr-text-in_item1">
                                <span className="pr-text-in_item2">
                                    <span className="pr-text-in_item3">
                                        Let's <b>Talk</b>
                                    </span>
                                </span>
                            </span>
                        </h2>
                    </div>
                    <div className="clinox-contact-form">
                        <form action="#" method="get">
                            <div className="row">
                                <div className="col-md-6">
                                    <input type="text" name="name" placeholder="Name" />
                                </div>
                                <div className="col-md-6">
                                    <input type="email" name="email" placeholder="Email" />
                                </div>
                                <div className="col-md-6">
                                    <input type="text" name="phone" placeholder="Phone" />
                                </div>
                                <div className="col-md-6">
                                    <div className="service-select position-relative">
                                        <select name="#">
                                            <option value="#">Service</option>
                                            <option value="#">Cleaning Services</option>
                                            <option value="#">Maintenance Services</option>
                                        </select>
                                    </div>
                                </div>
                                
                            </div>
                            <button type="submit">Submit Now</button>
                        </form>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</div>
</section>		



<Footer/>
</div>
            
        </Fragment>
    )
}

export default AirConditioning;