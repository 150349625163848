import React, { Fragment } from "react";
import { Link } from "react-router-dom";

function Footer(){
  return(

    <Fragment>
        <footer data-background="assets/img/bg/footer-noise.png" />
<div className="footer">
			<ul className="footer__social">
				<li><Link to="https://www.facebook.com/BlueGenieDubai/"><i className="fab fa-facebook-f"></i>Facebook</Link></li>
				<li><Link to="#!"><i className="fab fa-twitter"></i>Twitter</Link></li>
				<li><Link to="https://www.instagram.com/bluegeniedubai/"><i className="fab fa-instagram"></i>Instagram</Link></li>
				<li><Link to="https://www.youtube.com/watch?v=RfHpp5IM2Hg" target="_blank"><i className="fab fa-youtube"></i>Youtube</Link></li>
				<li><Link to="#!"><i className="fab fa-pinterest"></i>Pinterest</Link></li>
			</ul>
			<div className="container mxw_1350">
				<div className="row pt-65 pb-65">
					<div className="col-lg-3 col-md-6 footer__col mt-30">
						<div className="footer__widget">
							<h3>Cleaning</h3>
							<ul className="footer__links list-unstyled">
								<li><Link to="/cleaning">Cleaning</Link></li>
							</ul>
						</div>
					</div>

 
					<div className="col-lg-3 col-md-6 footer__col mt-30">
						<div className="footer__widget">
							<h3>Maintenance</h3>
							<ul className="footer__links list-unstyled">
								<li><Link to="/airconditioning">Air Conditioning</Link></li>
								<li><Link to="/cleaning">Cleaning</Link></li>
								
							</ul>
						</div>
					</div>
					<div className="col-lg-3 col-md-6 footer__col mt-30">
						<div className="footer__widget">
							<h3>USEFUL LINKS</h3>
							<ul className="footer__links list-unstyled">
								<li><Link to="/about">About us</Link></li>
								<li><Link to="/contact">Contact us</Link></li>
							</ul>
						</div>
					</div>
					<div className="col-lg-3 col-md-6 footer__col mt-30">
						<div className="footer__widget">
							<h3>CONTACT US</h3>
							<div className="footer__awards">
								2601, U-Bora Office Tower, Business Bay, Dubai-74873, UAE<br />
								M: +971 55 433 8822<br />
								E: hello@bluegenie.ae
							</div>
						</div>
					</div>
				</div>
				<div className="footer__bottom ul_li_between">
					<div className="footer__copyright mt-15">
						© Copyright ©2023 <Link to="#!">Blue Genie</Link>. All Rights Reserved Copyright
					</div>
					<ul className="footer__nav ul_li mt-15">
						<li><Link to="#!">Terms and conditions</Link></li>
						<li><Link to="#!"> Privacy policy</Link></li>
						<li><Link to="#!"></Link></li>
					</ul>
				</div>
			</div>
            </div>
	<footer />
    </Fragment>
  )  
}

export default Footer;