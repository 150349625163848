import React, { Fragment } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";


function AnnualContract(){

    return(

        <Fragment>


<div className="body_wrap">


<div className="progress-wrap">
    <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
    <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"/>
    </svg>
</div>


<Header />


 <aside className="slide-bar">
    <div className="close-mobile-menu">
        <a href="javascript:void(0);"><i className="fal fa-times"></i></a>
    </div>




    <nav className="side-mobile-menu">
        <ul id="mobile-menu-active">
           <li><a href="index.html">Home</a></li>
            <li><a href="about-us.html">About us</a></li>
            <li className="dropdown">
                <a href="#">Cleaning</a>
                <ul className="submenu clearfix">
                    <li><a href="home-cleaning-services.html">Home Cleaning Services</a></li>
                    <li><a href="home-disinfection-service.html">Home Disinfection Service</a></li>
                    <li><a href="pest-control.html">Pest Control</a></li>
                    <li><a href="deep-cleaning-services.html">Deep Cleaning Services</a></li>
                    <li><a href="home-sanitization-service.html">Home Sanitization Service</a></li>
                    <li><a href="move-in-cleaning.html">Move In Cleaning</a></li>
                    <li><a href="sofa-cleaning.html">Sofa Cleaning</a></li>
                    <li><a href="handyman-services.html">Handyman Services</a></li>
                    <li><a href="housekeeping-services.html">Housekeeping Services</a></li>
                    <li><a href="babysitting-service.html">Babysitting Service</a></li>
                </ul>
            </li>
            <li className="dropdown">
                <a href="#">Maintenance</a>
                <ul className="submenu clearfix">
                    <li><a href="air-conditioning.html">Air Conditioning</a></li>
                    <li><a href="cleaning.html">Cleaning</a></li>
                    <li><a href="electrical.html">Electrical</a></li>
                    <li><a href="plumbing.html">Plumbing</a></li>
                    <li><a href="disinfection.html">Disinfection</a></li>
                    <li><a href="tiling-and-carepentry.html">Tiling and Carepentry</a></li>
                </ul>
            </li>
            <li><a href="annual-contract.html">Annual Contract</a></li>
          <li><a href="#">Communities</a></li>
            <li><a href="contact.html">Contact us</a></li>
        </ul>
    </nav>
  
</aside>

<div className="body-overlay"></div>



<section id="clinox-breadcrumb" className="clinox-breadcrumb-section position-relative top-position" data-background="assets/img/annual-contract.jpg">
<div className="banner-shape position-absolute"><img src="assets/img/banner/bread-bg.png" alt="" /></div>
<div className="container">
    <div className="breadcrumb-content headline ul-li position-relative">
        <h2>Annual Contract</h2>
        <ul>
            <li><Link to="/">Home</Link></li>
            <li>Annual Contract</li>
        </ul>
    </div>
</div>
</section>

<section id="clinox-project-feed" className="clinox-project-feed-section page-section-padding">
<div className="container">

<div className="tab">
        <button className="tablinks" onclick="openCity(event, 'Monthly')" id="defaultOpen">Monthly</button>
        <button className="tablinks" onclick="openCity(event, 'half-yearly')">Half yearly</button>
      <button className="tablinks" onclick="openCity(event, 'Yearly')">Yearly</button>

    </div>


        <div id="Monthly" className="tabcontent">
          <div className="row">
          <div className="col-lg-4 col-sm-6">
            <div className="clinox-pricing-item headline text-center ul-li-block">
                    <div className="price-header-icon-value">
                        <h3>Elite Package</h3>
                    </div>
                    <div className="startingform-text"><h6>Starting from AED 370*</h6></div>
                    <div className="price-feature d-flex justify-content-center">
                        <ul>
                            <li>Total # of hours = 10 hours per month</li>
                            <li>Minimum hours per day = 2 hours per visit</li>
                            <li>Price range monthly</li>
                            <li className="sub-details">With materials – AED 399</li>
                            <li className="sub-details">Without materials – AED 370</li>
                            <li>Hourly rates</li>
                            <li className="sub-details">With materials – AED 38</li>
                            <li className="sub-details">Without materials – AED 34.286</li>
                        </ul>
                    </div>
                    <div className="price-btn d-flex justify-content-center">
                        <a className="d-flex justify-content-center align-items-center" href="#clinox-contact">Select Package</a>
                    </div>
                </div>
        </div>
        <div className="col-lg-4 col-sm-6">
            <div className="clinox-pricing-item headline text-center ul-li-block">
                    <div className="price-header-icon-value">
                        <h3>Master Package</h3>
                    </div>
                    <div className="startingform-text"><h6>Starting from AED 520*</h6></div>
                    <div className="price-feature d-flex justify-content-center">
                        <ul>
                            <li>Total # of hours = 15 hours per month</li>
                            <li>Minimum hours per day = 3 hours per visit</li>
                            <li>Price range monthly</li>
                            <li className="sub-details">With materials – AED 585</li>
                            <li className="sub-details">Without materials – AED 519.75</li>
                            <li>Hourly rates</li>
                            <li className="sub-details">With materials – AED 37.143</li>
                            <li className="sub-details">Without materials – AED 33</li>
                        </ul>
                    </div>
                    <div className="price-btn d-flex justify-content-center">
                        <a className="d-flex justify-content-center align-items-center" href="#clinox-contact">Select Package</a>
                    </div>
                </div>
        </div>
        <div className="col-lg-4 col-sm-6">
            <div className="clinox-pricing-item headline text-center ul-li-block">
                    <div className="price-header-icon-value">
                        <h3>Epic Package </h3>
                    </div>
                    <div className="startingform-text"><h6>Starting from AED 670*</h6></div>
                    <div className="price-feature d-flex justify-content-center">
                        <ul>
                            <li>Total # of hours: 20 hours per month</li>
                            <li>Minimum hours per day: 4 hours per visit</li>
                            <li>Price range monthly</li>

                            <li className="sub-details">With materials – AED 756</li>
                            <li className="sub-details">Without materials – AED 670</li>
                            <li>Hourly rates</li>
                            <li className="sub-details">With materials – AED 36</li>
                            <li className="sub-details">Without materials – AED 31.9</li>
                        </ul>
                    </div>
                    <div className="price-btn d-flex justify-content-center">
                        <a className="d-flex justify-content-center align-items-center" href="#clinox-contact">Select Package</a>
                    </div>
                </div>
        </div>
        </div>
        <div className="row mt-30">
        <div className="col-lg-4 col-sm-6">
            <div className="clinox-pricing-item headline text-center ul-li-block">
                    <div className="price-header-icon-value">
                        <h3>Legendary Package </h3>
                  </div>

                  <div className="startingform-text"><h6>Starting from AED 945*</h6></div>
                    <div className="price-feature d-flex justify-content-center">
                        <ul>
                            <li>Total # of hours: 30 hours per month</li>
                            <li>Minimum hours per day: 3 hours per visit</li>
                            <li>Price range monthly</li>
                            <li className="sub-details">With materials – AED 1,100</li>
                            <li className="sub-details">Without materials – AED 945</li>
                            <li>Hourly rates</li>
                            <li className="sub-details">With materials – AED 34.9205</li>
                            <li className="sub-details">Without materials – AED 30</li>
                        </ul>
                    </div>
                    <div className="price-btn d-flex justify-content-center">
                        <a className="d-flex justify-content-center align-items-center" href="#clinox-contact">Select Package</a>
                    </div>
                </div>
        </div>
        <div className="col-lg-4 col-sm-6">
            <div className="clinox-pricing-item headline text-center ul-li-block">
                    <div className="price-header-icon-value">
                        <h3>Mythic Package</h3>
                    </div>
                    <div className="startingform-text"><h6>Starting from AED 1220*</h6></div>
                    <div className="price-feature d-flex justify-content-center">
                        <ul>
                            <li>Total # of hours: 40 hours per month</li>
                            <li>Minimum hours per day: 4 hours per visit</li>
                            <li>Price range monthly</li>
                            <li className="sub-details">With materials – AED 1400</li>
                            <li className="sub-details">Without materials – AED 1220</li>
                            <li>Hourly rates</li>
                            <li className="sub-details">With materials – AED 33.3333</li>
                            <li className="sub-details">Without materials – AED 29.0475</li>
                        </ul>
                    </div>
                    <div className="price-btn d-flex justify-content-center">
                        <a className="d-flex justify-content-center align-items-center" href="#clinox-contact">Select Package</a>
                    </div>
                </div>
        </div>
        </div>
        </div>

        <div id="half-yearly" className="tabcontent">
            <div className="row">
          <div className="col-lg-4 col-sm-6">
            <div className="clinox-pricing-item headline text-center ul-li-block">
                    <div className="price-header-icon-value">
                        <h3>Elite Package</h3>
                        <div className="startingform-text"><h6>Starting from AED 2,190*</h6></div>
                        <h4 className="pac-off">5% off</h4>
                    </div>

                    <div className="price-feature d-flex justify-content-center">
                        <ul>
                            <li>Total # of hours = 10 hours per month</li>
                            <li>Minimum hours per day = 2 hours per visit</li>
                            <li>Price range monthly</li>
                            <li className="sub-details">With materials – AED 399</li>
                            <li className="sub-details">Without materials – AED 370</li>
                            <li>Hourly rates</li>
                            <li className="sub-details">With materials – AED 38</li>
                            <li className="sub-details">Without materials – AED 34.286</li>
                        </ul>
                    </div>
                    <div className="price-btn d-flex justify-content-center">
                        <a className="d-flex justify-content-center align-items-center" href="#clinox-contact">Select Package</a>
                    </div>
                </div>
        </div>
        <div className="col-lg-4 col-sm-6">
            <div className="clinox-pricing-item headline text-center ul-li-block">
                    <div className="price-header-icon-value">
                        <h3>Master Package</h3>
                        <div className="startingform-text"><h6>Starting from AED 3,120*</h6></div>
                        <h4 className="pac-off">5% off</h4>
                    </div>

                    <div className="price-feature d-flex justify-content-center">
                        <ul>
                            <li>Total # of hours = 15 hours per month</li>
                            <li>Minimum hours per day = 3 hours per visit</li>
                            <li>Price range monthly</li>
                            <li className="sub-details">With materials – AED 585</li>
                            <li className="sub-details">Without materials – AED 519.75</li>
                            <li>Hourly rates</li>
                            <li className="sub-details">With materials – AED 37.143</li>
                            <li className="sub-details">Without materials – AED 33</li>
                        </ul>
                    </div>
                    <div className="price-btn d-flex justify-content-center">
                        <a className="d-flex justify-content-center align-items-center" href="#clinox-contact">Select Package</a>
                    </div>
                </div>
        </div>
        <div className="col-lg-4 col-sm-6">
            <div className="clinox-pricing-item headline text-center ul-li-block">
                    <div className="price-header-icon-value">
                        <h3>Epic Package </h3>
                        <div className="startingform-text"><h6>Starting from AED 3,820*</h6></div>
                        <h4 className="pac-off">5% off</h4>
                    </div>

                    <div className="price-feature d-flex justify-content-center">
                        <ul>
                            <li>Total # of hours: 20 hours per month</li>
                            <li>Minimum hours per day: 4 hours per visit</li>
                            <li>Price range monthly</li>
                            <li className="sub-details">With materials – AED 756</li>
                            <li className="sub-details">Without materials – AED 670</li>
                            <li>Hourly rates</li>
                            <li className="sub-details">With materials – AED 36</li>
                            <li className="sub-details">Without materials – AED 31.9</li>
                        </ul>
                    </div>
                    <div className="price-btn d-flex justify-content-center">
                        <a className="d-flex justify-content-center align-items-center" href="#clinox-contact">Select Package</a>
                    </div>
                </div>
        </div>
        </div>
        <div className="row mt-30">
        <div className="col-lg-4 col-sm-6">
            <div className="clinox-pricing-item headline text-center ul-li-block">
                    <div className="price-header-icon-value">
                        <h3>Legendary Package </h3>
                        <div className="startingform-text"><h6>Starting from AED 5,340*</h6></div>
                        <h4 className="pac-off">5% off</h4>
                    </div>


                    <div className="price-feature d-flex justify-content-center">
                        <ul>
                            <li>Total # of hours: 30 hours per month</li>
                            <li>Minimum hours per day: 3 hours per visit</li>
                            <li>Price range monthly</li>
                            <li className="sub-details">With materials – AED 1,100</li>
                            <li className="sub-details">Without materials – AED 945</li>
                            <li>Hourly rates</li>
                            <li className="sub-details">With materials – AED 34.9205</li>
                            <li className="sub-details">Without materials – AED 30</li>
                        </ul>
                    </div>
                    <div className="price-btn d-flex justify-content-center">
                        <a className="d-flex justify-content-center align-items-center" href="#clinox-contact">Select Package</a>
                    </div>
                </div>
        </div>
        <div className="col-lg-4 col-sm-6">
            <div className="clinox-pricing-item headline text-center ul-li-block">
                    <div className="price-header-icon-value">
                        <h3>Mythic Package</h3>
                        <div className="startingform-text"><h6>Starting from AED 6,955*</h6></div>
                        <h4 className="pac-off">5% off</h4>
                    </div>

                    <div className="price-feature d-flex justify-content-center">
                        <ul>
                            <li>Total # of hours: 40 hours per month</li>
                            <li>Minimum hours per day: 4 hours per visit</li>
                            <li>Price range monthly</li>
                            <li className="sub-details">With materials – AED 1400</li>
                            <li className="sub-details">Without materials – AED 1220</li>
                            <li>Hourly rates</li>
                            <li className="sub-details">With materials – AED 33.3333</li>
                            <li className="sub-details">Without materials – AED 29.0475</li>
                        </ul>
                    </div>
                    <div className="price-btn d-flex justify-content-center">
                        <a className="d-flex justify-content-center align-items-center" href="#clinox-contact">Select Package</a>
                    </div>
                </div>
        </div>
        </div>
        </div>
        <div id="Yearly" className="tabcontent">
            <div className="row">
          <div className="col-lg-4 col-sm-6">
            <div className="clinox-pricing-item headline text-center ul-li-block">
                    <div className="price-header-icon-value">
                        <h3>Elite Package</h3>
                        <div className="startingform-text"><h6>Starting from AED 3,996*</h6></div>
                        <h4 className="pac-off">10% off</h4>
                    </div>
                    <div className="price-feature d-flex justify-content-center">
                        <ul>
                            <li>Total # of hours = 10 hours per month</li>
                            <li>Minimum hours per day = 2 hours per visit</li>
                            <li>Price range monthly</li>
                            <li className="sub-details">With materials – AED 399</li>
                            <li className="sub-details">Without materials – AED 370</li>
                            <li>Hourly rates</li>
                            <li className="sub-details">With materials – AED 38</li>
                            <li className="sub-details">Without materials – AED 34.286</li>
                        </ul>
                    </div>
                    <div className="price-btn d-flex justify-content-center">
                        <a className="d-flex justify-content-center align-items-center" href="#clinox-contact">Select Package</a>
                    </div>
                </div>
        </div>
        <div className="col-lg-4 col-sm-6">
            <div className="clinox-pricing-item headline text-center ul-li-block">
                    <div className="price-header-icon-value">
                        <h3>Master Package</h3>
                        <div className="startingform-text"><h6>Starting from AED 5,615*</h6></div>
                        <h4 className="pac-off">10% off</h4>
                    </div>
                    <div className="price-feature d-flex justify-content-center">
                        <ul>
                            <li>Total # of hours = 15 hours per month</li>
                            <li>Minimum hours per day = 3 hours per visit</li>
                            <li>Price range monthly</li>
                            <li className="sub-details">With materials – AED 585</li>
                            <li className="sub-details">Without materials – AED 519.75</li>
                            <li>Hourly rates</li>
                            <li className="sub-details">With materials – AED 37.143</li>
                            <li className="sub-details">Without materials – AED 33</li>
                        </ul>
                    </div>
                    <div className="price-btn d-flex justify-content-center">
                        <a className="d-flex justify-content-center align-items-center" href="#clinox-contact">Select Package</a>
                    </div>
                </div>
        </div>
        <div className="col-lg-4 col-sm-6">
            <div className="clinox-pricing-item headline text-center ul-li-block">
                    <div className="price-header-icon-value">
                        <h3>Epic Package </h3>
                        <div className="startingform-text"><h6>Starting from AED 7,236*</h6></div>
                        <h4 className="pac-off">10% off</h4>
                    </div>
                    <div className="price-feature d-flex justify-content-center">
                        <ul>
                            <li>Total # of hours: 20 hours per month</li>
                            <li>Minimum hours per day: 4 hours per visit</li>
                            <li>Price range monthly</li>
                            <li className="sub-details">With materials – AED 756</li>
                            <li className="sub-details">Without materials – AED 670</li>
                            <li>Hourly rates</li>
                            <li className="sub-details">With materials – AED 36</li>
                            <li className="sub-details">Without materials – AED 31.9</li>
                        </ul>
                    </div>
                    <div className="price-btn d-flex justify-content-center">
                        <a className="d-flex justify-content-center align-items-center" href="#clinox-contact">Select Package</a>
                    </div>
                </div>
        </div>
        </div>
        <div className="row mt-30">
        <div className="col-lg-4 col-sm-6">
            <div className="clinox-pricing-item headline text-center ul-li-block">
                    <div className="price-header-icon-value">
                        <h3>Legendary Package </h3>
                        <div className="startingform-text"><h6>Starting from AED 10,206*</h6></div>
                        <h4 className="pac-off">10% off</h4>
                    </div>
                    <div className="price-feature d-flex justify-content-center">
                        <ul>
                            <li>Total # of hours: 30 hours per month</li>
                            <li>Minimum hours per day: 3 hours per visit</li>
                            <li>Price range monthly</li>
                            <li className="sub-details">With materials – AED 1,100</li>
                            <li className="sub-details">Without materials – AED 945</li>
                            <li>Hourly rates</li>
                            <li className="sub-details">With materials – AED 34.9205</li>
                            <li className="sub-details">Without materials – AED 30</li>
                        </ul>
                    </div>
                    <div className="price-btn d-flex justify-content-center">
                        <a className="d-flex justify-content-center align-items-center" href="#clinox-contact">Select Package</a>
                    </div>
                </div>
        </div>
        <div className="col-lg-4 col-sm-6">
            <div className="clinox-pricing-item headline text-center ul-li-block">
                    <div className="price-header-icon-value">
                        <h3>Mythic Package</h3>
                        <div className="startingform-text"><h6>Starting from AED 13,180*</h6></div>
                        <h4 className="pac-off">10% off</h4>
                    </div>
                    <div className="price-feature d-flex justify-content-center">
                        <ul>
                            <li>Total # of hours: 40 hours per month</li>
                            <li>Minimum hours per day: 4 hours per visit</li>
                            <li>Price range monthly</li>
                            <li className="sub-details">With materials – AED 1400</li>
                            <li className="sub-details">Without materials – AED 1220</li>
                            <li>Hourly rates</li>
                            <li className="sub-details">With materials – AED 33.3333</li>
                            <li className="sub-details">Without materials – AED 29.0475</li>
                        </ul>
                    </div>
                    <div className="price-btn d-flex justify-content-center">
                        <a className="d-flex justify-content-center align-items-center" href="#clinox-contact">Select Package</a>
                    </div>
                </div>
        </div>
        </div>
        </div>


</div>
</section>


<section className="annual-contract-top-bg">
<div className="container">
<div className="row">
    <div className="col-lg-12">
        <div className="annual-contract-top-text d-flex justify-content-center py-4">
            <div className="package-text"><h3>“ Do you want a customized package or need any advice? ”</h3>
                <p>Provide us the details, we will contact you.</p>
                <a className="action" href="#clinox-contact">Let Us Know</a>
            </div>
            </div>
    </div>
</div>
</div>
</section>



<div className="couter counter__bg">
        <div className="container">
            <div className="counter__wrapper">
                <div className="row align-items-center">
                    <div className="col-lg-5">
                        <div className="counter__video pos-rel ul_li">
                            <a href="https://www.youtube.com/watch?v=RfHpp5IM2Hg" className="popup-video popup-video__md"><i className="fas fa-play"></i></a>
                            <h3>Let's  Estimate :</h3>
                        </div>
                        <div className="counter__item">
                            <div className="counter__number">

                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="counter__wrap ul_li_between">
                            <div className="counter__item">
                                <h3><span className="counter">109</span>k+</h3>
                                <p>Worlwide Customers</p>
                                <img src="assets/img/icon/world.png" alt="" />
                            </div>
                            <div className="counter__item">
                                <h3><span className="counter">58</span>%</h3>
                                <p>Worlwide Customers</p>
                                <img src="assets/img/icon/world.png" alt="" />
                            </div>
                            <div className="counter__item">
                                <h3><span className="counter">85</span>k+</h3>
                                <p>Worlwide Customers</p>
                                <img src="assets/img/icon/world.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
 </div>



    <div className="testimonial pb-180" data-background="assets/img/bg/testimonial_bg.jpg">
        <div className="container">
            <div className="testimonial__cta ul_li_between">
                <h3>Want to <span>consult</span> with our team ?</h3>
                <a className="thm-btn thm-btn__icon" href="#">
                    <span className="btn-wrap">
                        <span>Get An Quate</span>
                        <span>Get An Quate</span>
                    </span>
                    <i className="flaticon-right-arrow"></i>
                </a>
                <div className="testimonial__cta-shape">
                    <img src="assets/img/shape/cta_shape.png" alt="" />
                </div>
            </div>
            <div className="testimonial__wrap pt-110">
                <div className="sec-title mb-50 text-center">
                    <span className="subtitle">Cleaning Company</span>
                    <h2 className="title">Expert in Cleaning Services</h2>
                </div>
                <div className="testimonial__slide-wrap">
                    <div className="testimonial__quote">
                        <span className="quote quote--1"><img src="assets/img/icon/quote-1.png" alt="" /></span>
                        <span className="quote quote--2"><img src="assets/img/icon/quote-2.png" alt="" /></span>
                    </div>
                    <div className="testimonial__slide">
                       
                       
                        <div className="testimonial__item">
                            <div className="testimonial__avatar">
                                <img src="assets/img/testimonial/3.jpg" alt="" />
                            </div>
                            <div className="testimonial__content">"I highly recommend Bluegenie Home Cleaning Services for their deep disinfection services. They were very accommodating with scheduling and took the time to explain their process and products. Their team arrived on time and did an excellent job, leaving my home feeling fresh and sanitized."</div>
                         
                        </div>
                        <div className="testimonial__item">
                            <div className="testimonial__avatar">
                                <img src="assets/img/testimonial/4.jpg" alt="" />
                            </div>
                            <div className="testimonial__content">"Bluegenie Home Cleaning Services did an exceptional job with our deep disinfection. The team was friendly, efficient, and thorough. They even provided helpful tips on maintaining a clean and sanitized home. I would definitely use their services again and recommend them to others."</div>
                          
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="testimonial__avatars">
            <img className="avatar1 wow fadeInLeft" data-wow-delay="0s" data-wow-duration="1500ms" src="assets/img/testimonial/avatar_01.jpg" alt="" />
            <img className="avatar2 wow fadeInUp" data-wow-delay=".2s" data-wow-duration="1500ms"  src="assets/img/testimonial/avatar_02.jpg" alt="" />
            <img className="avatar3 wow fadeInRight" data-wow-delay=".4s" data-wow-duration="1500ms"  src="assets/img/testimonial/avatar_03.jpg" alt="" />
            <img className="avatar4 wow fadeInUp" data-wow-delay=".6s" data-wow-duration="1500ms"  src="assets/img/testimonial/avatar_04.jpg" alt="" />
        </div>
    </div>
  


<section id="clinox-contact" className="clinox-contact-section">
<div className="container">
    <div className="clinox-contact-content">
        <div className="row">
        <div className="col-lg-6">
                        <div className="row">
                            <div className="col-md-12">
                                <img src="assets/img/lets-talk.jpg" alt="" />
                            </div>
                        </div>

            </div>
            <div className="col-lg-6">
                <div className="clinox-contact-form-wrap">
                    <div className="clinox-section-title headline pera-content pr-text-in">
                        <h2>
                            <span className="pr-text-in_item1">
                                <span className="pr-text-in_item2">
                                    <span className="pr-text-in_item3">
                                        Let's <b>Talk</b>
                                    </span>
                                </span>
                            </span>
                        </h2>
                    </div>
                    <div className="clinox-contact-form">
                        <form action="#" method="get">
                            <div className="row">
                                <div className="col-md-6">
                                    <input type="text" name="name" placeholder="Name" />
                                </div>
                                <div className="col-md-6">
                                    <input type="email" name="email" placeholder="Email" />
                                </div>
                                <div className="col-md-6">
                                    <input type="text" name="phone" placeholder="Phone" />
                                </div>
                                <div className="col-md-6">
                                    <div className="service-select position-relative">
                                        <select name="#">
                                            <option value="#">Service</option>
                                            <option value="#">Cleaning Services</option>
                                            <option value="#">Maintenance Services</option>
                                        </select>
                                    </div>
                                </div>

                            </div>
                            <button type="submit">Submit Now</button>
                        </form>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
</section>
<Footer/>


</div>

        </Fragment>
    )
}

export default AnnualContract;