import React, { Fragment } from "react";
import { Link } from "react-router-dom";

function Header(){

    return(
    
 <Fragment>
<header className="header">
    <div className="header__top-wrap">
        <div className="container">
            <div className="header__top ul_li_between mt-none-15">
                <div className="header__social mt-15">
                    <Link to={"https://www.facebook.com/BlueGenieDubai/"}><i className="fab fa-facebook-f" /></Link>
                    <Link to={"https://www.instagram.com/bluegeniedubai/"}><i className="fab fa-instagram"></i></Link>
                    <Link to={"https://www.youtube.com/watch?v=RfHpp5IM2Hg" }><i className="fab fa-youtube"></i></Link>
</div>
                <ul className="header__info ul_li mt-15">
                    <li><i className="far fa-map-marker-alt"></i>2601, U-Bora Office Tower, Business Bay, Dubai-74873</li>
                    <li><i className="far fa-envelope"></i>hello@bluegenie.ae</li>
                    
                </ul>
            </div>
        </div>
    </div>
    <div className="header__main-wrap" data-uk-sticky="top: 250; animation: uk-animation-slide-top;">
        <div className="container">
            <div className="header__main ul_li_between">
                <div className="header__main-left ul_li flex-1">
                    <div className="header__bar hamburger_menu">
                    <Link to="/"><img src="assets/img/icon/bar-2.svg" alt="" /></Link>
                    </div>
                    <div className="header__logo ml-50">
                    <Link to="/"><img src="assets/img/logo.png" alt="" /></Link>
                    </div>
                </div>
                <div className="main-menu navbar navbar-expand-lg">
                    <nav className="main-menu__nav collapse navbar-collapse">
                        <ul>
                            <li><Link to="/about">About us</Link></li>
                            <li><Link to="/cleaning">Cleaning</Link></li>
                            <li class="dropdown menu-item-has-children"><Link to="#">Maintenance</Link>
                            <ul class="submenu clearfix">
                            <li><Link to="/airconditioning">Air Conditioning</Link></li>
                            <li><Link to="/cleaning">Cleaning</Link></li>
                        </ul>
                </li>

                            <li><Link to="/annualcontract">Annual Contract</Link></li>
                            <li><Link to="/contact">Contact us</Link></li>
                        </ul>
                    </nav>
                </div>
                <div className="header__main-right ul_li">
                    <div className="header__btn ml-40">
                    <Link className="thm-btn thm-btn--transparent br-0" to="/contact">
                        <span className="btn-wrap">
                                <span>Book Now</span>
                         </span>
                    </Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>
</Fragment>
   
   )
}

export default Header;