import React from 'react';
import { Fragment } from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import img1 from "../testimonial/1.jpg"
import img2 from "../testimonial/2.jpg"
import img3 from "../testimonial/3.jpg"
import img4 from "../testimonial/4.jpg"
import { Link } from 'react-router-dom';

function HomeTestimonial() {

    return (

        <Fragment>

            <div class="testimonial pb-180" data-background="assets/img/bg/testimonial_bg.jpg">
                <div class='container' >
                <div class="testimonial__cta ul_li_between">
						<h3>Want to <span>consult</span> with our team ?</h3>
						<Link class="thm-btn thm-btn__icon" to={""}>
							<span class="btn-wrap">
								<span>Get An Quate</span>
							</span>
							<i class="flaticon-right-arrow"></i>
						</Link>
						<div class="testimonial__cta-shape">
							<img src="assets/img/shape/cta_shape.png" alt="" />
						</div>
					</div>
                    <div class="testimonial__wrap pt-110">
                    <div class="sec-title mb-50 text-center">
							<span class="subtitle">Cleaning Company</span>
							<h2 class="title">Expert in Cleaning Services</h2>
					</div>
                    <div class="testimonial__slide-wrap">
                        <div class="testimonial__quote">
                            <span class="quote quote--1"><img src="assets/img/icon/quote-1.png" alt="" /></span>
                            <span class="quote quote--2"><img src="assets/img/icon/quote-2.png" alt="" /></span>
                        </div>
                        </div>
                        <OwlCarousel className="OwlCarousel owl-theme testimonial__slide" items={1} margin={8} autoplay={true} >



                            <div className="item testimonial__item">
                                <div class="testimonial__avatar">
                                    <img className="img" src={img1} alt='' />
                                </div>
                                <div class="testimonial__content">"I recently used Bluegenie Home Cleaning Services for a deep disinfection of my home and was extremely satisfied with their work. The team was very professional and thorough, making sure every corner of my house was disinfected. I felt much safer and more at ease after their visit."</div>
                            </div>

                            <div className="item testimonial__item">
                                <div class="testimonial__avatar">
                                    <img className="img" src={img2} alt=''/>
                                </div>
                                <div class="testimonial__content">"I have been using Bluegenie Home Cleaning Services for regular cleaning for several months now, but recently hired them for a deep disinfection. I was impressed with their attention to detail and use of high-quality disinfectant products. I feel much more comfortable having them come in and clean during these uncertain times."</div>
                            </div>

                            <div className="item testimonial__item">
                                <div class="testimonial__avatar">
                                    <img className="img" src={img3} alt='' />
                                </div>
                                <div class="testimonial__content">"I highly recommend Bluegenie Home Cleaning Services for their deep disinfection services. They were very accommodating with scheduling and took the time to explain their process and products. Their team arrived on time and did an excellent job, leaving my home feeling fresh and sanitized."</div>

                            </div>


                            <div className="item testimonial__item">
                                <div class="testimonial__avatar">
                                    <img className="img" src={img4}  alt=''/>
                                </div>
                                <div class="testimonial__content">"Bluegenie Home Cleaning Services did an exceptional job with our deep disinfection. The team was friendly, efficient, and thorough. They even provided helpful tips on maintaining a clean and sanitized home. I would definitely use their services again and recommend them to others."</div>

                            </div>
                        </OwlCarousel>
                    </div></div>
                
                <div class="testimonial__avatars">
                    <img class="avatar1 wow fadeInLeft" data-wow-delay="0s" data-wow-duration="1500ms" src="assets/img/testimonial/avatar_01.jpg" alt="" />
                    <img class="avatar2 wow fadeInUp" data-wow-delay=".2s" data-wow-duration="1500ms" src="assets/img/testimonial/avatar_02.jpg" alt="" />
                    <img class="avatar3 wow fadeInRight" data-wow-delay=".4s" data-wow-duration="1500ms" src="assets/img/testimonial/avatar_03.jpg" alt="" />
                    <img class="avatar4 wow fadeInUp" data-wow-delay=".6s" data-wow-duration="1500ms" src="assets/img/testimonial/avatar_04.jpg" alt="" />
                </div>
            </div>

        </Fragment>
    )
}

export default HomeTestimonial;