import Home from './pages/Home';
import About from './pages/About';
import Cleaning from './pages/Cleaning';
import AirConditioning from './pages/AirConditioning';
import AnnualContract from './pages/AnnualContract';
import Contact from './pages/Contact';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
function App() {
  return (
    <div className="App">
    <Router>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/cleaning' element={<Cleaning />} />
          <Route path='/airconditioning' element={<AirConditioning />} />
          <Route path='/annualcontract' element={<AnnualContract />} />
          <Route path='/contact' element={<Contact />} />
          
        </Routes>
      </Router>
    </div>
  );
  
}

export default App;
