import React, { Fragment } from "react";
import Header from '../components/Header';
import Footer from "../components/Footer";
import { Link } from "react-router-dom";

function About(){

    return(

 <Fragment>
            	
                <div className="body_wrap">



<div className="progress-wrap">
    <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
    <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
    </svg>
</div>


<Header />



 <aside className="slide-bar">
    <div className="close-mobile-menu">
        <Link to={""}><i className="fal fa-times"></i></Link>
    </div>

    

    
</aside>
<div className="body-overlay"></div>




<section id="clinox-breadcrumb" className="clinox-breadcrumb-section position-relative top-position" data-background="assets/img/about-head.jpg" >

<div className="container">
    <div className="breadcrumb-content headline ul-li position-relative">
        <h2>About Us</h2>
        <ul>
            <li><Link to="/">Home</Link></li>
            <li>About Us</li>
        </ul>
    </div>
</div>
</section>	



<section id="clinox-about-2" className="clinox-about-section-2">
<div className="container">
    <div className="clinox-about-content-2">
        <div className="row">
        
            <div className="col-lg-6">
                <div className="clinox-about-text-wrap-2">
                    <div className="clinox-section-title-2  headline pera-content pr-text-in">
                        
                        <h2>
                            <span className="pr-text-in_item1">
                                <span className="pr-text-in_item2">
                                    <span className="pr-text-in_item3">
                                        We Provides Qualityful
                                        Cleaning & Maintenance Service
                                    </span>
                                </span>
                            </span>
                        </h2>
                        <p>Blue Genie is a one-stop solution for all your home cleaning, maintenance, and related needs. We provide top-notch services to ensure that your home is always in top condition.</p>
<p>Our team of experts is highly trained and equipped with the latest tools and techniques to provide the best service experience. Whether you need regular cleaning, deep cleaning, or any home maintenance services, we've got you covered.</p>
<p>At Blue Genie, we understand that your home is a precious place, and we are committed to providing you with the highest level of service. Contact us today for all your home-related needs.
</p>
                    </div>
                    <div className="about-feature-item-wrap-2 ul-li-block wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                        <ul>
                            <li>Expert Home Cleaning & Maintenance Services at Your Doorstep.</li>
                            <li>Say Goodbye to Dirty Homes with Our Comprehensive Cleaning Solutions.</li>
                            <li>Transform Your Sofa with Our Professional Upholstery Cleaning.</li>
                            <li>Top-Notch Home Cleaning Services for a Fresh & Healthy Living Environment.</li>
                            <li>Elevate Your Home's Cleanliness with Our Comprehensive Cleaning Packages.</li>
                            <li>Get a Spotless Home with Our Expert Cleaning and Maintenance Services.</li>
                        </ul>
                    </div>
                    
                </div>
            </div>
            <div className="col-lg-6">
                <div className="clinox-about-img-wrap-2 d-flex justify-content-end position-relative">
                    <span className="star-vector1 position-absolute"><img src="assets/img/shape1.png" alt="" /></span>
                    <span className="star-vector2 position-absolute"><img src="assets/img/shape2.png" alt="" /></span>
                    <div className="clinox-about-img1 position-relative text-right">
                        <span className="img-shape position-absolute" data-parallax='{"y" : 25}'></span>
                        <img src="assets/img/about-01.jpg" alt="" />
                    </div>
                    <div className="clinox-about-img2 position-absolute">
                        <span className="img-shape position-absolute" data-parallax='{"y" : 15}'></span>
                        <img src="assets/img/about-02.jpg" alt="" />
                    </div>
                </div>
                <div className="about__img mr-65 pos-rel">
                        <img className="wow fadeInLeft" data-wow-delay="0s" data-wow-duration="1500ms" alt="" />
                        <div className="about__cta ul_li a_cta-left wow fadeInUp" data-wow-delay=".3s" data-wow-duration="1500ms">
                            <img src="assets/img/icon/call.svg" alt="" />
                            <span>Call Us <br /> Directly</span>
                            <h3><Link to={"tel:+971 55 433 8822"}>+971 55 433 8822</Link></h3>
                            <Link className="action arrow" to={""}><i className="flaticon-right-arrow"></i></Link>
                        </div>
                    </div>
            </div>
            
        </div>
    </div>
</div>
</section>



<section id="clinox-how-work-3" className="clinox-how-work-section-3" data-background="assets/img/img-5/bg/hw-bg.jpg">
<div className="container">
    <div className="clinox-section-title-3 text-center headline pera-content wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
        <div className="subtitle text-uppercase">
            HOW WE WORK
        </div>
        <h2>How BlueGenie Work Done
            For Customers
        </h2>
    </div>
    <div className="clinox-how-work-content-3 position-relative">
        <span className="line-shape position-absolute"><img src="assets/img/img-5/line-sh2.png" alt="" /></span>
        <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="400ms" data-wow-duration="1500ms">
                <div className="clinox-how-work-item-3 text-center position-relative">
                    <span className="serial d-flex justify-content-center align-items-center position-absolute">01</span>
                    <div className="inner-icon position-relative d-flex justify-content-center align-items-center">
                        <img src="assets/img/img-5/icon/ic8.png" alt="" />
                    </div>
                    <div className="inner-text headline pera-content">
                        <h3>Book Appointment</h3>
                        <p>Our agency can only be as strong as our people & because of this.</p>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="600ms" data-wow-duration="1500ms">
                <div className="clinox-how-work-item-3 text-center position-relative">
                    <span className="serial d-flex justify-content-center align-items-center position-absolute">02</span>
                    <div className="inner-icon position-relative d-flex justify-content-center align-items-center">
                        <img src="assets/img/img-5/icon/ic9.png" alt="" />
                    </div>
                    <div className="inner-text headline pera-content">
                        <h3>Choose Services</h3>
                        <p>Our agency can only be as strong as our people & because of this.</p>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="800ms" data-wow-duration="1500ms">
                <div className="clinox-how-work-item-3 text-center position-relative">
                    <span className="serial d-flex justify-content-center align-items-center position-absolute">03</span>
                    <div className="inner-icon position-relative d-flex justify-content-center align-items-center">
                        <img src="assets/img/img-5/icon/ic10.png" alt="" />
                    </div>
                    <div className="inner-text headline pera-content">
                        <h3>Enjoy Cleanliness</h3>
                        <p>Our agency can only be as strong as our people & because of this.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</section>

 <div className="couter counter__bg">
        <div className="container">
            <div className="counter__wrapper">
                <div className="row align-items-center">
                    <div className="col-lg-5">
                        <div className="counter__video pos-rel ul_li">
                            <Link to={"https://www.youtube.com/watch?v=RfHpp5IM2Hg"}  className="popup-video popup-video__md"><i className="fas fa-play"></i></Link>
                            <h3>Let,s Estimate :</h3>
                        </div>
                        <div className="counter__item">
                            <div className="counter__number">

                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="counter__wrap ul_li_between">
                            <div className="counter__item">
                                <h3><span className="counter">109</span>k+</h3>
                                <p>Worlwide Customers</p>
                                <img src="assets/img/icon/world.png" alt="" />
                            </div>
                            <div className="counter__item">
                                <h3><span className="counter">58</span>%</h3>
                                <p>Worlwide Customers</p>
                                <img src="assets/img/icon/world.png" alt="" />
                            </div>
                            <div className="counter__item">
                                <h3><span className="counter">85</span>k+</h3>
                                <p>Worlwide Customers</p>
                                <img src="assets/img/icon/world.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
   

    <div className="testimonial pb-60" data-background="assets/img/bg/testimonial_bg.jpg">
        <div className="container">
            <div className="testimonial__cta ul_li_between">
                <h3>Want to <span>consult</span> with our team ?</h3>
                <Link className="thm-btn thm-btn__icon" to={"contact"} >
                    <span className="btn-wrap">
                        <span>Get An Quate</span>
                        <span>Get An Quate</span>
                    </span>
                    <i className="flaticon-right-arrow"></i>
                </Link>
                <div className="testimonial__cta-shape">
                    <img src="assets/img/shape/cta_shape.png" alt="" />
                </div>
            </div>
            
        </div>
        
    </div>
    			


       
        <div className="about about__bg-two pt-45 pb-45 mb-90">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="sec-title sec-title__two mb-50">
                            <span className="subtitle wow fadeInUp" data-wow-delay="0s" data-wow-duration="1500ms">Cleaning Company</span>
                            <h2 className="title wow fadeInUp" data-wow-delay=".3s" data-wow-duration="1500ms">
                                Why will you choose our <br /> services for your ?
                            </h2>
                        </div>
                        <div className="tab-info__wrap">
                            <div className="tab-info__item tab-info__item-two d-flex wow fadeInUp" data-wow-delay=".3s" data-wow-duration="1500ms">
                                <div className="icon">
                                    <i className="flaticon-house"></i>
                                    <img src="assets/img/shape/a_shape.svg" alt="" />
                                </div>
                                <div className="content">
                                    <h3>Customer Focused Reviews</h3>
                                    <p>On the other hand we denounce with righteous indignation dislike men who are so beguiled and demoralized by</p>
                                </div>
                            </div>
                            <div className="tab-info__item tab-info__item-two d-flex wow fadeInUp" data-wow-delay=".5s" data-wow-duration="1500ms">
                                <div className="icon">
                                    <i className="flaticon-cloth"></i>
                                    <img src="assets/img/shape/a_shape.svg" alt="" />
                                </div>
                                <div className="content">
                                    <h3>Customer Focused Reviews</h3>
                                    <p>On the other hand we denounce with righteous indignation <br /> dislike men who are so beguiled and demoralized by</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="about__image">
                            <img className="img1 wow fadeInRight" data-wow-delay=".3s" data-wow-duration="1500ms" src="assets/img/main-shape.jpg" alt="" />
                            <img className="img2 wow fadeInRight" data-wow-delay="0s" data-wow-duration="1500ms" src="assets/img/about/img_01.jpg" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
   </div>

<Footer />

        </Fragment>
    )
}

export default About;