import React, { Fragment } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";


function Contact() {

    return (

        <Fragment>


            <div className="body_wrap">


                <div className="progress-wrap">
                    <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
                        <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
                    </svg>
                </div>

                <Header />

                <aside className="slide-bar" />
                    <div className="close-mobile-menu">
                        <Link to={""}><i className="fal fa-times"></i></Link>
                    </div>



                <aside /> 
                <div className="body-overlay"></div>

            
    <section id="clinox-breadcrumb" className="clinox-breadcrumb-section position-relative top-position" data-background="assets/img/annual-contract.jpg">
<div className="banner-shape position-absolute"><img src="assets/img/banner/bread-bg.png" alt="" /></div>
<div className="container">
    <div className="breadcrumb-content headline ul-li position-relative">
        <h2>Contact Us</h2>
        <ul>
            <li><Link to="/">Home</Link></li>
            <li>Contact Us</li>
        </ul>
    </div>
</div>
</section>




    <section id="clinox-booking-form" class="clinox-booking-form-section page-section-padding" />
		<div class="container">
			<div class="booking-form-content">
				<div class="row">
					<div class="col-lg-6">
						<div class="booking-form-img">
							<div class="clinox-faq-img-wrap position-relative">
								<span class="bg-shape position-absolute view-on"></span>
								<div class="faq-img1 bg-img-area view-on">
									<img src="assets/img/about/wc2.jpg" alt="" />
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-6">
						<div class="clinox-contact-form-wrap">
							<div class="clinox-section-title headline pera-content">
								<span class="sub-title">Get In Touch</span>
								<h2>Have Any
									<span>Question?</span>
								</h2>
							</div>
							<div class="clinox-contact-form">
								<form action="send-mail.php" method="post" />
									<div class="row">
										<div class="col-md-6">
										    <input type="hidden" name="page" value="Contact Us Page" />
										    <input type="hidden" name="redirect_url" value="contact.html" />
											<input type="text" name="name" placeholder="Name" />
										</div>
										<div class="col-md-6">
											<input type="email" name="email" placeholder="Email" />
										</div>
										<div class="col-md-6">
											<input type="text" name="phone" placeholder="Phone" />
										</div>
										<div class="col-md-6">
											<div class="service-select position-relative">
												<select name="service_type">
													<option value="Service">Service</option>
													<option value="Cleaning Services">Cleaning Services</option>
													<option value="Maintenance Services">Maintenance Services</option>
												</select>
											</div>
										</div>
										<div class="col-md-12">
											<textarea name="message" placeholder="Message"></textarea>
										</div>
									</div>
									<button type="submit">Submit Now</button>
								<form />
							</div>
						</div>
					</div>
				</div>
				<div class="booking-form-cta-content">
					<div class="clinox-section-title text-center headline pera-content">
						<span class="sub-title">Contact Us</span>
						<h2>Contact
							<span>Information.</span>
						</h2>
					</div>
					<div class="row justify-content-center">
						<div class="col-lg-4 col-md-6">
							<div class="booking-cta-item d-flex">
								<div class="inner-icon">
									<img src="assets/img/icon/ic15.png" alt="" />
								</div>
								<div class="inner-text headline">
									<h4>Office Address:</h4>
									<span>2601, U-Bora Office Tower, Business Bay, Dubai-74873</span>
								</div>
							</div>
						</div>
						<div class="col-lg-4 col-md-6">
							<div class="booking-cta-item d-flex">
								<div class="inner-icon">
									<img src="assets/img/icon/ic16.png" alt="" />
								</div>
								<div class="inner-text headline">
									<h4>Mail Us </h4>
									<span>hello@bluegenie.ae</span>
									<span>info@bluegenie.ae</span>
								</div>
							</div>
						</div>
						<div class="col-lg-4 col-md-6">
							<div class="booking-cta-item d-flex">
								<div class="inner-icon">
									<img src="assets/img/icon/ic17.png" alt="" />
								</div>
								<div class="inner-text headline">
									<h4>Telephone</h4>
									<span>+971 55 433 8822</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	<section />


                <section className="clinox-how-work-section-3 no-top-padd" data-background="assets/img/img-5/bg/hw-bg.jpg" />
                <div className="row justify-content-center">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.6726745951946!2d55.26724531500922!3d25.180527983903986!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f69ccba0aebfd%3A0x35b9a2d30f642f56!2sU%20BORA%20Tower%202%20GE%20Office!5e0!3m2!1sen!2sin!4v1675400698827!5m2!1sen!2sin" width="100%" height="450" style={{border: "0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
                <section />
            </div>

            <Footer />


        </Fragment>
    )
}

export default Contact;