import React from 'react';
import { Fragment } from "react";
import Header from '../components/Header';
import Footer from '../components/Footer';
import videoBg from "../videoplayer/videoBg.mp4";
import HomeTestimonial from "./HomeTestimonail";

function Home(){

    return(
        
        <Fragment>

<div className="body_wrap">

{/* <!-- back to top start --> */}
<div className="progress-wrap">
    <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
    <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"/>
    </svg>
</div>
{/* <!-- back to top end --> */}

{/* <!-- header start --> */}
<Header />
{/* <!-- header end --> */}

 {/* <!-- slide bar start --> */}
 <aside className="slide-bar">
    <div className="close-mobile-menu">
        <a href="javascript:void(0);"><i className="fal fa-times"></i></a>
    </div>
</aside>

<div className="body-overlay"></div>
{/* <!-- slide bar end --> */}

<main />
    {/* <!-- hero start --> */}
    <div className="hero__two">
           
        <div className="row align-items-center mt-none-30" style={{overflow: "hidden"}}>
                <div className="col-lg-12 mt-28">   
                    <div className="hero__content hero__content-two">
                    <video src={videoBg} autoPlay loop muted />
                    <div className="hero__text-box hero__content hero__content-two">
                        
                            <h2 className="" data-wow-delay=".3s" data-wow-duration="1500ms">Cleaning & Maintenance <br />
                                Best <span>Services in Dubai</span> </h2>
                        <p className="wow fadeInUp" data-wow-delay=".6s" data-wow-duration="1500ms">Take the hassle out of cleaning with our fast response times, <br />competitive pricing, and great customer experience.</p>
                        <div className="hero__btn wow fadeInUp" data-wow-delay=".8s" data-wow-duration="1500ms">
                            <a className="thm-btn br-0" href="#">
                                <span className="btn-wrap">
                                    <span>Get An Quote</span>
                                    <span>Get An Quote</span>
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
                </div>
            
        
        

        
        
    </div>
    {/* <!-- hero end --> */}

    {/* <!-- feature start --> */}
    <div className="feature pb-60">
        <div className="container">
            <div className="row">
                <div className="col-lg-4 col-md-12">
                    <div className="feature__info-box">
                        <span>Our Features</span>
                        <h3 className="title">A cleaning solution from <br /> Blue Genie offers</h3>
                        <div>
                            <div className="feature__info-box-item d-flex">
                                <div className="icon">
                                    <i className="flaticon-house"></i>
                                </div>
                                <div className="content">
                                    <h3>Discover a new way</h3>
                                    <p>Blue Genie offers customized professional cleaning solutions tailored to your exact needs in Dubai. </p>
                                </div>
                            </div>
                            <div className="feature__info-box-item d-flex">
                                <div className="icon">
                                    <i className="flaticon-data-cleaning"></i>
                                </div>
                                <div className="content">
                                    <h3>Top-Rated Company</h3>
                                    <p>Turn your home into a perfect oasis of cleanliness at an affordable price
</p>
                                </div>
                            </div>
                        </div>
                        <div className="feature__btn mt-40">
                            <a className="thm-btn br-0" href="services.html">
                                <span className="btn-wrap">
                                    <span>More Features</span>
                                    
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-8">
                    <div className="cta__wrap cta__two ul_li">
                        <div className="cta__info">
                            <h3>Let's Estimate :</h3>
                            <p className="form-msg"></p>
                        </div>
                        <form action="send-mail.php" className="cta__form" id="One-Way" method="POST" />
                            <input type="hidden" name="page" value="Home Page" />
                            <input type="email" name="email" placeholder="Your Email *" />
                            <div className="service-select">
                                <select name="service_type">
                                    <option value="Cleaning">Cleaning</option>
                                    <option value="Maintenance">Maintenance</option>
                                </select>
                            </div>
                            <button className="thm-btn" type="submit">
                                <span className="btn-wrap">
                                    <span>Submit Now</span>
                                    <span>Submit Now</span>
                                </span>
                            </button>
                        <form />
                    </div>
                    <div className="feature__content-wrap ul_li pt-20 mt-140">
                        <div className="feature__img">
                            <img src="assets/img/bg/feature_img.png" alt="" />
                        </div>
                        <div className="feature__content">
                            <p>Transform your living and work spaces in just a few short hours!
</p>
                            <ul className="feature__list list-unstyled">
                                <li>Experienced Team</li>
                                <li>Keep the same cleaner for every visit</li>
                                <li>Book, manage & pay online</li>
                            </ul>
                            <h4 className="name">Kundan Choudhary <span>Ceo & Founder</span></h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Start of Service Feed section
============================================= --> */}

{/* <!-- Start of project feed section
============================================= --> */}
<section id="clinox-project-feed" className="clinox-project-feed-section page-section-padding">
<div className="container">
    <div className="sec-title text-center sec-title__two mb-50">
        <span className="subtitle wow fadeInUp" data-wow-delay="0s" data-wow-duration="1500ms">Cleaning & Maintenance Company</span>
        <h2 className="title wow fadeInUp" data-wow-delay=".3s" data-wow-duration="1500ms">We Are Committed To Give Our Best Services</h2>
    </div>
    <div className="tab">
      <button className="tablinks" onclick="openCity(event, 'Cleaning')" id="defaultOpen">Cleaning</button>
      <button className="tablinks" onclick="openCity(event, 'Maintenance')">Maintenance</button>
    </div>
    
        <div id="Cleaning" className="tabcontent">
            <div className="row">
          <div className="clinox-service-feed-content">
        <div className="row">
            <div className="col-lg-4 col-md-6">
                <div className="clinex-service-item-2 text-center box-01">
                    <div className="inner-icon d-flex justify-content-center align-items-center">
                        <img src="assets/img/window-cleaning.png" alt="" style={{width: "100%"}} />
                    </div>
                    <div className="inner-text headline pera-content">
                        <h3><a href="cleaning.html">Cleaning Services Dubai</a></h3>
                        <p>Blue Genie use high-quality products and equipment to ensure a deep clean, removing dirt, dust, and grime from all surfaces.</p>
                        <div className="blog__btn mt-25">
                            <a href="cleaning.html" className="read-more">Read More<i className="flaticon-right-arrow"></i></a>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6">
                <div className="clinex-service-item-2 text-center box-02">
                    <div className="inner-icon d-flex justify-content-center align-items-center">
                        <img src="assets/img/carpet-cleaning.png" alt="" style={{width: "100%"}} />
                    </div>
                    <div className="inner-text headline pera-content">
                        <h3><a href="home-cleaning-services.html">Home Cleaning Services</a></h3>
                        <p>Blue Genie services offer a convenient solution for busy homeowners looking to maintain a clean and tidy home. </p>
                        <div className="blog__btn mt-25">
                            <a href="home-cleaning-services.html" className="read-more">Read More<i className="flaticon-right-arrow"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6">
                <div className="clinex-service-item-2 text-center box-03">
                    <div className="inner-icon d-flex justify-content-center align-items-center">
                        <img src="assets/img/office-cleaning.png" alt="" style={{width: "100%"}} />
                    </div>
                    <div className="inner-text headline pera-content">
                        <h3><a href="home-disinfection-service.html">Home Disinfection Service</a></h3>
                        <p>Blue Genie Home Disinfection Service provides comprehensive cleaning and disinfection services.</p>
                        <div className="blog__btn mt-25">
                            <a href="home-disinfection-service.html" className="read-more">Read More<i className="flaticon-right-arrow"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6">
                <div className="clinex-service-item-2 text-center box-02">
                    <div className="inner-icon d-flex justify-content-center align-items-center">
                        <img src="assets/img/plumbing-service.png" alt="" style={{width: "100%"}} />
                    </div>
                    <div className="inner-text headline pera-content">
                        <h3><a href="deep-cleaning-services.html">Deep Cleaning Services</a></h3>
                        <p>Blue Genie provides top-notch deep cleaning services that ensure a thorough cleaning of your home or office space.</p>
                        <div className="blog__btn mt-25">
                            <a href="deep-cleaning-services.html" className="read-more">Read More<i className="flaticon-right-arrow"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6">
                <div className="clinex-service-item-2 text-center box-01">
                    <div className="inner-icon d-flex justify-content-center align-items-center">
                        <img src="assets/img/house-cleaning.png" alt="" style={{width: "100%"}} />
                    </div>
                    <div className="inner-text headline pera-content">
                        <h3><a href="home-sanitization-service.html">Home Sanitization Service</a></h3>
                        <p>Blue Genie Home Sanitization Services offers complete disinfection solutions to keep your home safe and healthy.</p>
                        <div className="blog__btn mt-25">
                            <a href="home-sanitization-service.html" className="read-more">Read More<i className="flaticon-right-arrow"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6">
                <div className="clinex-service-item-2 text-center box-03">
                    <div className="inner-icon d-flex justify-content-center align-items-center">
                        <img src="assets/img/car-wash.png" alt="" style={{width: "100%"}} />
                    </div>
                    <div className="inner-text headline pera-content">
                        <h3><a href="move-in-cleaning.html">Move In Cleaning</a></h3>
                        <p>experienced cleaners pay close attention to detail and ensure that your new home is spotless and ready for you to move in.</p>
                        <div className="blog__btn mt-25">
                            <a href="move-in-cleaning.html" className="read-more">Read More<i className="flaticon-right-arrow"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div className="inner-text headline pera-content"><a className="read-more d-flex justify-content-center align-items-center" href="services.html">View More</a></div>
    </div>
        </div>
        </div>
        <div id="Maintenance" className="tabcontent">
          <div className="row">
          <div className="clinox-service-feed-content">
        <div className="row">
            <div className="col-lg-4 col-md-6">
                <div className="clinex-service-item-2 text-center box-01">
                    <div className="inner-icon d-flex justify-content-center align-items-center">
                        <img src="assets/img/ac.png" alt="" style={{width: "100%"}} />
                    </div>
                    <div className="inner-text headline pera-content">
                        <h3><a href="air-conditioning.html">Air Conditioning</a></h3>
                        <p>With long experience, we offer repair, maintenance and installation services to ensure your AC unit is functioning at its best.</p>
                        <div className="blog__btn mt-25">
                            <a href="air-conditioning.html" className="read-more">Read More<i className="flaticon-right-arrow"></i></a>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6">
                <div className="clinex-service-item-2 text-center box-02">
                    <div className="inner-icon d-flex justify-content-center align-items-center">
                        <img src="assets/img/carpet-cleaning.png" alt="" style={{width: "100%"}} />
                    </div>
                    <div className="inner-text headline pera-content">
                        <h3><a href="cleaning.html">Cleaning</a></h3>
                        <p>Keep your home or office spotless with Blue Genie Cleaning Services. Our techniques provide deep and thorough clean.</p>
                        <div className="blog__btn mt-25">
                            <a href="cleaning.html" className="read-more">Read More<i className="flaticon-right-arrow"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6">
                <div className="clinex-service-item-2 text-center box-03">
                    <div className="inner-icon d-flex justify-content-center align-items-center">
                        <img src="assets/img/electrical.png" alt="" style={{width: "100%"}} />
                    </div>
                    <div className="inner-text headline pera-content">
                        <h3><a href="electrical.html">Electrical</a></h3>
                        <p>We specialize in electrical maintenance and installation – from replacing defective bulbs and switches, to installing lighting.</p>
                        <div className="blog__btn mt-25">
                            <a href="electrical.html" className="read-more">Read More<i className="flaticon-right-arrow"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6">
                <div className="clinex-service-item-2 text-center box-02">
                    <div className="inner-icon d-flex justify-content-center align-items-center">
                        <img src="assets/img/plumbing-service.png" alt="" style={{width: "100%"}} />
                    </div>
                    <div className="inner-text headline pera-content">
                        <h3><a href="plumbing.html">Plumbing</a></h3>
                        <p>We provide professional services that are backed by our Done Right Guarantee, you can rest assured that your repair will be done right.</p>
                        <div className="blog__btn mt-25">
                            <a href="plumbing.html" className="read-more">Read More<i className="flaticon-right-arrow"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6">
                <div className="clinex-service-item-2 text-center box-01">
                    <div className="inner-icon d-flex justify-content-center align-items-center">
                        <img src="assets/img/deinfection.png" alt="" style={{width: "100%"}} />
                    </div>
                    <div className="inner-text headline pera-content">
                        <h3><a href="disinfection.html">Disinfection</a></h3>
                        <p>Blue Genie Disinfection Services is a company that specializes in providing top-notch disinfection services for homes, businesses, and public spaces.</p>
                        <div className="blog__btn mt-25">
                            <a href="disinfection.html" className="read-more">Read More<i className="flaticon-right-arrow"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6">
                <div className="clinex-service-item-2 text-center box-03">
                    <div className="inner-icon d-flex justify-content-center align-items-center">
                        <img src="assets/img/carepentry.png" alt="" style={{width: "100%"}} />
                    </div>
                    <div className="inner-text headline pera-content">
                        <h3><a href="tiling-and-carepentry.html">Tiling and Carepentry</a></h3>
                        <p>Blue Genie provides top-notch tiling and carpentry services. Their skilled craftsmen specialize in a variety of tile installations, including ceramic...</p>
                        <div className="blog__btn mt-25">
                            <a href="tiling-and-carepentry.html" className="read-more">Read More<i className="flaticon-right-arrow"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div className="inner-text headline pera-content"><a className="read-more d-flex justify-content-center align-items-center" href="services.html">View More</a></div>
    </div>
        </div>
        </div>

        
    
</div>
</section>	
{/* <!-- End of project feed section
============================================= -->	 */}


    {/* <!-- about start --> */}
    <div className="about">
        <div className="container">
            <div className="row align-items-center mt-none-30">
                <div className="col-lg-6 mt-30">
                    <div className="about__img mr-65 pos-rel">
                        <img className="wow fadeInLeft" data-wow-delay="0s" data-wow-duration="1500ms" src="assets/img/about-img.jpg" alt="" />
                        <div className="about__cta a_cta_l ul_li wow fadeInUp" data-wow-delay=".3s" data-wow-duration="1500ms">
                            <img src="assets/img/icon/call.svg" alt="" />
                            <span>Call Us <br /> Directly</span>
                            <h3><a href="tel:+971 55 433 8822">+971 55 433 8822</a></h3>
                            <a className="action" href="https://wa.me/+971554338822"><i className="fa fa-whatsapp" aria-hidden="true"></i>
</a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 mt-30">
                    <div className="about__content">
                        <div className="sec-title sec-title__two mb-35">
                            <span className="subtitle wow fadeInUp" data-wow-delay="0s" data-wow-duration="1500ms">About us</span>
                            <h2 className="title mb-25 wow fadeInUp" data-wow-delay=".3s" data-wow-duration="1500ms">
                                Why will you choose our <br /> services for your ?
                            </h2>
                            <p className="wow fadeInUp" data-wow-delay=".6s" data-wow-duration="1500ms">Blue Genie offers the best cleaning services in Dubai. We are proud to have satisfied thousands of customers, who are happy with our high quality and affordable house cleaning and office cleaning services. Our hotel-trained professional cleaners will take care of your Home Cleaning, Deep Cleaning, Move in Cleaning, Move out Cleaning, Villa and Apartment Cleaning and Housekeeping needs.</p>
                        </div>
                        <ul className="about__list list-unstyled mb-40 wow fadeInUp" data-wow-delay=".8s" data-wow-duration="1500ms">
                            <li>Mix and match colors, sizes, and designs</li>
                            <li>Top quality prints using the latest technology</li>
                        </ul>
                        
                        <div className="about__text wow fadeInUp" data-wow-delay="1s" data-wow-duration="1500ms"><img src="assets/img/icon/fire.png" alt="" />We provide fast <span>on-cleaning</span> services.</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- about end --> */}

    
       
<section id="clinox-how-work-3" className="clinox-how-work-section-3" data-background="assets/img/img-5/bg/hw-bg.jpg">
<div className="container">
    <div className="clinox-section-title-3 text-center headline pera-content wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
        <div className="subtitle text-uppercase">
            HOW WE WORK
        </div>
        <h2>How BlueGenie Work Done
            For Customers
        </h2>
    </div>
    <div className="clinox-how-work-content-3 position-relative">
        <span className="line-shape position-absolute"><img src="assets/img/img-5/line-sh2.png" alt="" /></span>
        <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="400ms" data-wow-duration="1500ms">
                <div className="clinox-how-work-item-3 text-center position-relative">
                    <span className="serial d-flex justify-content-center align-items-center position-absolute">01</span>
                    <div className="inner-icon position-relative d-flex justify-content-center align-items-center">
                        <img src="assets/img/img-5/icon/ic8.png" alt="" />
                    </div>
                    <div className="inner-text headline pera-content">
                        <h3>Book Appointment</h3>
                        <p>Our agency can only be as strong as our people & because of this.</p>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="600ms" data-wow-duration="1500ms">
                <div className="clinox-how-work-item-3 text-center position-relative">
                    <span className="serial d-flex justify-content-center align-items-center position-absolute">02</span>
                    <div className="inner-icon position-relative d-flex justify-content-center align-items-center">
                        <img src="assets/img/img-5/icon/ic9.png" alt="" />
                    </div>
                    <div className="inner-text headline pera-content">
                        <h3>Choose Services</h3>
                        <p>Our agency can only be as strong as our people & because of this.</p>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="800ms" data-wow-duration="1500ms">
                <div className="clinox-how-work-item-3 text-center position-relative">
                    <span className="serial d-flex justify-content-center align-items-center position-absolute">03</span>
                    <div className="inner-icon position-relative d-flex justify-content-center align-items-center">
                        <img src="assets/img/img-5/icon/ic10.png" alt="" />
                    </div>
                    <div className="inner-text headline pera-content">
                        <h3>Enjoy Cleanliness</h3>
                        <p>Our agency can only be as strong as our people & because of this.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</section>
        


        {/* <!-- about start --> */}
        <div className="about about__bg-two pt-45 pb-45 ">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="sec-title sec-title__two mb-50">
                            <span className="subtitle wow fadeInUp" data-wow-delay="0s" data-wow-duration="1500ms">Cleaning Company</span>
                            <h2 className="title wow fadeInUp" data-wow-delay=".3s" data-wow-duration="1500ms">
                                Why will you choose our <br /> services for your ?
                            </h2>
                        </div>
                        <div className="tab-info__wrap">
                            <div className="tab-info__item tab-info__item-two d-flex wow fadeInUp" data-wow-delay=".3s" data-wow-duration="1500ms">
                                <div className="icon">
                                    <i className="flaticon-house"></i>
                                    <img src="assets/img/shape/a_shape.svg" alt="" />
                                </div>
                                <div className="content">
                                    <h3>Customer Focused Reviews</h3>
                                    <p>On the other hand we denounce with righteous indignation dislike men who are so beguiled and demoralized by</p>
                                </div>
                            </div>
                            <div className="tab-info__item tab-info__item-two d-flex wow fadeInUp" data-wow-delay=".5s" data-wow-duration="1500ms">
                                <div className="icon">
                                    <i className="flaticon-cloth"></i>
                                    <img src="assets/img/shape/a_shape.svg" alt="" />
                                </div>
                                <div className="content">
                                    <h3>Customer Focused Reviews</h3>
                                    <p>On the other hand we denounce with righteous indignation <br /> dislike men who are so beguiled and demoralized by</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="about__image">
                            <img className="img1 wow fadeInRight" data-wow-delay=".3s" data-wow-duration="1500ms" src="assets/img/about/img_02.jpg" alt="" />
                            <img className="img2 wow fadeInRight" data-wow-delay="0s" data-wow-duration="1500ms" src="assets/img/about/img_01.jpg" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- about end --> */}
    </div>

    {/* <!-- counter start --> */}
    <div className="couter counter__bg">
        <div className="container">
            <div className="counter__wrapper">
                <div className="row align-items-center">
                    <div className="col-lg-5">
                        <div className="counter__video pos-rel ul_li">
                            <a href="https://www.youtube.com/watch?v=RfHpp5IM2Hg" className="popup-video popup-video__md"><i className="fas fa-play"></i></a>
                            <h3>Let,s Estimate :</h3>
                        </div>
                        <div className="counter__item">
                            <div className="counter__number">

                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="counter__wrap ul_li_between">
                            <div className="counter__item">
                                <h3><span className="counter">109</span>k+</h3>
                                <p>Worlwide Customers</p>
                                <img src="assets/img/icon/world.png" alt="" />
                            </div>
                            <div className="counter__item">
                                <h3><span className="counter">58</span>%</h3>
                                <p>Worlwide Customers</p>
                                <img src="assets/img/icon/world.png" alt="" />
                            </div>
                            <div className="counter__item">
                                <h3><span className="counter">85</span>k+</h3>
                                <p>Worlwide Customers</p>
                                <img src="assets/img/icon/world.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    {/* <!-- counter end --> */}

    {/* <!-- testimonial start --> */}
    <HomeTestimonial />
    </div>
    
<main />
<Footer />
</Fragment>
    )
}

export default Home;